import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin, EmbedPlugin, MediaPlugin } from 'bootstrap-vue'
import VueSimpleMarkdown from 'vue-simple-markdown'
//import 'vue-simple-markdown/dist/vue-simple-markdown.css'
//import './assets/css/markdown-reset.css'
import JwPagination from 'jw-vue-pagination';
import VueGtag from "vue-gtag";
import {VueMasonryPlugin} from 'vue-masonry'
import VueMeta from 'vue-meta'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/styles.css'
import './assets/css/gobmx.css'
import './assets/css/tables.css'
import './assets/css/material-colors.css'
import './assets/css/breakpoints.css'
import './assets/css/timeline.css'


Vue.config.productionTip = false

Vue.use(BootstrapVue, IconsPlugin, EmbedPlugin, MediaPlugin)
Vue.use(require('vue-moment'))
Vue.use(VueSimpleMarkdown)
Vue.component('jw-pagination', JwPagination)
Vue.use(VueMasonryPlugin)
Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: { 
    id: "UA-141778381-2",
    params: {
      send_page_view: true
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
