<template>
  <div class="component-header">
    <section class="header">
      <HeaderTopMenu msg="HeaderTopMenu"/>
      <HeaderMenuMovil msg="HeaderMenuMovil"/>
      <HeaderNavbar msg="HeaderNavbar"/>
    </section>



  </div>
</template>

<script>
import HeaderTopMenu from '@/components/layout/HeaderTopMenu.vue';
import HeaderNavbar from '@/components/layout/HeaderNavbar.vue';
import HeaderMenuMovil from '@/components/layout/HeaderMenuMovil.vue';

export default {
  name: 'Header',
  components:{
    HeaderTopMenu,
    HeaderNavbar,
    HeaderMenuMovil,
  },
  props: {
    msg: String
  },

}
</script>
