<template>
  <div class="attention-ime-three-columns">
    <b-container class="mt-5 mb-5">
      <b-row class="">
        <b-col cols="12" class="">
          <h1><i class="fas fa-hands-helping"></i> {{title}}</h1>
          <hr class="line sm">
        </b-col>
      </b-row>
      <b-row class="mt-2" align-h="center">
        <b-col 
          class="mb-4"
          col lg="3" md="6" sm="12"
          v-for="category in categories" 
          :key="category.id"
          >
            <h4 class="rojo-text text-center">
              <b-link :to="`${category.slug}`"><!-- Ajustar para Slug -->
                {{ category.name }}
              </b-link>
            </h4>
            <b-img
              fluid-grow
              :src="`${ path.storage_files + category.image_cover.formats.medium.url }`"
              :alt="`${ category.image_cover.name }`"
              class="z-depth-2"
            ></b-img >
            <p>{{ category.description }}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DataCategories from "@/services/data-categories";

export default {
  name: 'AttentionImeFourColumns',
  components: {
    DataCategories,
  },

  props: {
    title: String,
  },

  data() {
    return {
      categories: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted() {
    this.retrieveCategories();
  },

  methods: {
    retrieveCategories() {
      DataCategories.getAllReorder()
      .then(response => {
        this.categories = response.data;
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    },
  },

}
</script>
