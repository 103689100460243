<template>
  <div class="d-lg-none">
    <b-button
      v-b-toggle.sidebar-1
      pill
      variant="danger"
      class="burger-button z-depth-2"
    >
      <i class="fa-solid fa-ellipsis-vertical"></i>
    </b-button>
    <b-sidebar
      id="sidebar-1"
      title="IME"
      backdrop-variant="dark"
      no-header
      backdrop
      shadow
    >
      <template #default="{ hide }">
        <div class="rojo-obscuro px-3 py-2">
          <b-img
            alt="Instituto de los Mexicanos en el Exterior"
            src="/images/ime-mx.png"
            class="logo"
          ></b-img>
        </div>

        <div class="px-3 py-2 mt-3">
          <ul class="menu-movil">
            <li>
              <b-link to="/">
                <i class="fa-solid fa-house"></i> &nbsp; Inicio
              </b-link>
            </li>

            <li v-for="start in startPrincipalMenu" :key="start.id">
              <b-link
                :href="
                  `${start.url_redirect ? start.url_redirect : start.slug}`
                "
              >
                <i class="fa-solid fa-link"></i> &nbsp;
                {{ start.name }}
              </b-link>
            </li>

            <li v-for="end in endPrincipalMenu" :key="end.id">
              <b-link
                :href="`${end.url_redirect ? end.url_redirect : end.slug}`"
              >
                <i class="fa-solid fa-link"></i> &nbsp;
                {{ end.name }}
              </b-link>
            </li>
          </ul>
        </div>

        <div class="px-3 py-2 mt-1" v-if="$route.meta.cfgShowMenu === true">
          <h6 class="text-uppercase blue-grey-text text-darken-1">
            {{ $route.meta.cfgNameCategory }}
          </h6>
          <ul class="menu-movil">
            <li>
              <b-link :to="`/${$route.meta.cfgCategory}/`">
                <i class="fa-solid fa-house-chimney-window"></i> &nbsp; Inicio
              </b-link>
            </li>
            <li>
              <b-link :to="`/${$route.meta.cfgCategory}/articulos`">
                <i class="fa-solid fa-file-lines"></i> &nbsp; Artículos
              </b-link>
            </li>
            <li>
              <b-link :to="`/${$route.meta.cfgCategory}/programas`">
                <i class="fa-solid fa-bezier-curve"></i> &nbsp; Programas
              </b-link>
            </li>
            <!--<li :to="`/${$route.meta.cfgCategory}/eventos`">
              <b-link >Eventos</b-link>
            </li>-->
          </ul>
        </div>

        <div class="px-3 py-2">
          <h6 class="text-uppercase blue-grey-text text-lighten-1 mt-1">
            Comunidades
          </h6>
          <ul class="menu-movil">
            <li v-for="community in communitiesMenu" :key="community.id">
              <b-link :href="`/${community.slug}`">
                <i class="fa-solid fa-angles-right"></i> &nbsp;
                {{ community.name }}
              </b-link>
            </li>
          </ul>
        </div>
      </template>
      <template #footer="{ hide }">
        <div
          class="d-flex verde-obscuro text-light align-items-center px-3 py-2"
        >
          <b-button size="sm" @click="hide" variant="success">Cerrar</b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import DataCategories from "@/services/data-categories";

export default {
  components: {
    DataCategories,
  },

  data() {
    return {
      error: null,
      errors: [],
      startPrincipalMenu: [],
      endPrincipalMenu: [],
      communitiesMenu: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg: {
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    };
  },

  mounted() {},

  /*
   * Configuracion Inicial
   */
  async created() {
    await fetch(this.path.endpoint + "/categories/?menu=start_principal")
      .then((response) => response.json())
      .then((data) => {
        this.startPrincipalMenu = data;
      })
      .catch((err) => {
        console.error(err);
      });

    await fetch(this.path.endpoint + "/categories/?menu=end_principal")
      .then((response) => response.json())
      .then((data) => {
        this.endPrincipalMenu = data;
      })
      .catch((err) => {
        console.error(err);
      });

    await fetch(this.path.endpoint + "/categories/?menu=communities")
      .then((response) => response.json())
      .then((data) => {
        this.communitiesMenu = data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Ajuste */
};
</script>
<style>
.b-sidebar > .b-sidebar-body {
  background: #f5f5f5;
}
</style>
