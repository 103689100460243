<template>
	<div class="component-home-page-slide">
		<section
			v-if="homepage.live === false"
			class="component-home-slide shadow-4"
		>
			<b-carousel
				id="carousel-1"
				v-model="slide"
				:interval="7500"
				fade
				controls
				indicators
				background="#fafafa"
				img-width="1440"
				img-height="580"
				@sliding-start="onSlideStart"
				@sliding-end="onSlideEnd"
			>
				<b-carousel-slide
					v-for="image in banners"
					:img-src="`${path.storage_files + image.url}`"
					:key="image.id"
				>
					<template #img>
						<a :href="image.link">
							<img
								class="d-block img-fluid w-100 h-100"
								:src="`${path.storage_files + image.images.url}`"
								alt="image slot"
							/>
						</a>
					</template>
				</b-carousel-slide>
			</b-carousel>
			<div class="orange darken-4">
				<b-container>
					<b-row class="orange darken-4 header-top-menu  white-text light">
						<b-col>
							<ScrollComponent></ScrollComponent>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</section>

		<section
			v-else-if="homepage.live === true"
			class="home-live grey darken-4 z-depth-5 white-text"
		>
			<b-container>
				<div class="pt-4">
					<h4 class=" text-center mb-4">
						¡En Vivo! &nbsp;
						<i class="fab fa-youtube"></i> &nbsp;
						<span class="light">{{ homepage.live_title }}</span>
					</h4>
					<b-embed
						type="iframe"
						aspect="21by9"
						:src="
							`https://www.youtube-nocookie.com/embed/${homepage.youtube_id}?rel=0`
						"
						allowfullscreen
						class="video"
					></b-embed>
					<p class="text-center mt-2">{{ homepage.live_description }}</p>
				</div>
			</b-container>
		</section>
	</div>
</template>

<script>
	import DataHomePage from "@/services/data-homepage";
	import ScrollComponent from "@/components/Custom/News/Scroll.vue";

	export default {
		name: "HomePageSlide",
		components: {
			DataHomePage,
			ScrollComponent,
		},

		props: {
			pageTitle: String,
		},

		data() {
			return {
				homepage: [],
				banners: {},
				slide: 0,
				sliding: null,
				path: {
					base_url: process.env.VUE_APP_BASE_URL,
					endpoint: process.env.VUE_APP_ENDPOINT,
					storage_files: process.env.VUE_APP_STORAGE_FILES,
				},
			};
		},

		mounted() {
			this.retrieveHomePage();
			this.loadBanners();
		},

		methods: {
			retrieveHomePage() {
				DataHomePage.getHomePage()
					.then((response) => {
						this.homepage = response.data;
					})
					.catch((e) => {
						console.log(e);
					});
			},

			loadBanners() {
				DataHomePage.getBanners()
					.then((response) => {
						this.banners = response.data;
					})
					.catch((e) => {
						console.log(e);
					});
			},

			onSlideStart(slide) {
				this.sliding = true;
			},
			onSlideEnd(slide) {
				this.sliding = false;
			},
		},
	};
</script>
