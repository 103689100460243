import axios from "axios";
import authHeader from "@/services/auth-header";

const API_URL_CALENDAR = process.env.VUE_APP_ENDPOINT_CALENDAR;
const API_VINCULACION = "https://api-vinculacion.k0s.ime.red/api/v2";
const TOKEN_VINCULACION =
	"6cff209356862feec09d8fe7894c96e5800a480782fc5f8d5a8b5e45d05d03ff930b74efbdd2c0f111030e4fea17b91da20c81eea6ee58b3949b6f353211e8dd6934f1332c7d7848b5479319289897eedf9b7200ef75ed54f5a84b2bda736da11216fc88403be29b27e31e4c2e82c52f2bb2f3f4960f1c0ec1b6be4a69b0bac3";

class wsExternal {
	getAllCalendarHome() {
		return axios.get(
			API_URL_CALENDAR + `/api/v2/calendars/?&sort[1]=updatedAt:desc`,
			{
				headers: authHeader(),
			}
		);
	}

	getAllCalendar(pageSize, page) {
		return axios.get(
			API_URL_CALENDAR +
				`/api/v2/calendars/?&sort[1]=updatedAt:desc&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
			{
				headers: authHeader(),
			}
		);
	}

	getEvent(uuid) {
		return axios.get(
			API_VINCULACION + `/global-weeks/?filters[uuid][$eq]=${uuid}`,
			{
				headers: {
					Authorization: "Bearer " + TOKEN_VINCULACION,
				},
			}
		);
	}

	getAllEvents(pageSize, page) {
		return axios.get(
			API_VINCULACION +
				`/global-weeks/?sort[1]=eventDate:asc&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
			{
				headers: {
					Authorization: "Bearer " + TOKEN_VINCULACION,
				},
			}
		);
	}

	getAllEventsHome() {
		return axios.get(API_VINCULACION + `/global-weeks/?sort[1]=eventDate:asc`, {
			headers: {
				Authorization: "Bearer " + TOKEN_VINCULACION,
			},
		});
	}
}

export default new wsExternal();
