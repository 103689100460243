<template>
	<b-carousel
		controls
		id="carousel-newsletter-2"
		class="newsletter-carousel"
		style="margin-top:0px;font-size:18px; margin-bottom:0px"
		v-model="slide"
		:interval="6000"
		img-width="1440"
		img-height="52"
		max-height="52"
		@sliding-start="onSlideStart"
		@sliding-end="onSlideEnd"
	>
		<b-carousel-slide
			class="text-left"
			img-blank
			v-for="(item, i) in getAllEvents"
			:key="i"
		>
			<div v-if="item">
				<i class="fas fa-calendar-alt"></i> &nbsp;
				<strong> {{ item.rme ? item.rme : item.answer.fullName }}:</strong>
				&nbsp; {{ item.eventName }} &nbsp;
				<a
					size="sm"
					class="box-5"
					:href="`/vinculacion/evento/${item.uuid}`"
					style="padding:4px 6px"
				>
					<i class="fa-solid fa-arrow-up-right-from-square"></i>
				</a>
			</div>
			<div v-else>NO</div>
		</b-carousel-slide>
	</b-carousel>
</template>

<script>
	import wsExternal from "@/services/external";

	export default {
		name: "ScrollComponent",

		components: {
			//Menu,
		},
		props: {
			msg: String,
		},

		data() {
			return {
				slide: 0,
				sliding: null,
				getAllEvents: {},
			};
		},

		mounted() {
			this.loadEvents(50, 1);
		},

		methods: {
			loadEvents(pageSize, page) {
				wsExternal.getAllEventsHome(pageSize, page).then(
					(response) => {
						this.getAllEvents = response.data.data;
					},
					(error) => {
						this.getAllEvents =
							(error.response && error.response.data) ||
							error.message ||
							error.toString();
					}
				);
			},

			onSlideStart(slide) {
				this.sliding = true;
			},
			onSlideEnd(slide) {
				this.sliding = false;
			},
		},
	};
</script>
