<template>
  <div class="last-posts-three">

    <b-container class="mt-5 mb-5 pt-2">
      <b-row class="">
        <b-col cols="12" class="">
          <h1><i class="fab fa-readme"></i> {{title}}</h1>
          <hr class="line sm">
        </b-col>
      </b-row>
      <b-row>
        <b-col 
          class="mb-4"
          col lg="4" md="6" sm="12"
          v-for="lpost in listPosts"
          :key="lpost.id"
          >
            <div class="image-cover box-10 mb-3 z-depth-2">
              <b-img
                v-bind:src="`${ lpost.image_home_local ? path.storage_files + lpost.image_home_local.formats.small.url : lpost.image_home }`" 
                fluid-grow 
                :alt="`${lpost.image_home_local.title}`" 
                class="zoom h-100"
              ></b-img>
            </div>
            <div class="block mt-1 mb-1">

              <b-badge 
                class="red darken-1" 
                v-b-tooltip.hover 
                title="Video Blog" 
                v-if="lpost.type_publication_id.id === 5 "
              >
                <i class="fab fa-youtube"></i>
              </b-badge>&nbsp;

              <b-badge class="rojo medium ">
                <i class="fas fa-tag"></i>&nbsp;
                {{ lpost.category_id.name }}
              </b-badge>&nbsp;
              <b-badge class="verde medium">
                <i class="fas fa-calendar-alt"></i>&nbsp;
                {{ lpost.published_at | moment('DD/MMM/YYYY') }} 
              </b-badge>
            </div>
            <h5 class="latest-post medium">
              <b-link :to="`${lpost.category_id.slug}/${lpost.canonical}`"><!-- Ajustar para Slug -->
                {{ lpost.title }}
              </b-link>
            </h5>
        </b-col>
      </b-row>
    </b-container>




  </div>
</template>

<script>
import DataBlogs from "@/services/data-blogs";

export default {
  name: 'LastPostsThree',
  components: {
    DataBlogs,
  },

  props: {
    title: String,
  },

  data() {
    return {
      listPosts:[],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted() {
    this.retrieveLastPostsThree();
  },

  methods: {


    retrieveLastPostsThree() {
      DataBlogs.getLastPostsThree()
      .then(response => {
          this.listPosts = response.data;
          console.log(response.data);
      })
      .catch(e => {
          console.log(e);
      });
    },


  },


}
</script>
