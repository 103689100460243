<template>
    <div class="upcoming-events-full rojo-obscuro mt-5 mb-5 z-depth-4" style="background-image: url('/images/aguila-roja-25.png');">
      <b-container class="white-text">
        <b-row class="">
          <b-col cols="12" class="mt-5 dorado-text">
            <h1><i class="fas fa-calendar-alt"></i> {{title}}</h1>
            <hr class="line">
          </b-col>
          <b-row class="mb-3">
            <b-col
              cols="6"
              class="mb-4 mt-4"
              v-for="event in nowEvents"
              :key="event.id"
              >
              <b-row class="  ">
                <b-col cols="4" class="">
                  <b-img
                    fluid-grow thumbnail
                    :src="`${ path.storage_files + event.cover_image.formats.thumbnail.url }`"
                    class="z-depth-2 "
                  ></b-img >
                </b-col>
                <b-col cols="8" class="">
                  <h4>
                    <b-link :to="`/eventos/${event.canonical}`" class=" ">
                      <span v-if=" hoy === event.date "><i class="fas fa-certificate fa-spin"></i>&nbsp;</span>
                      <span class="strong grey-text text-lighten-5 text-shadow">{{ event.title }}</span>
                    </b-link>
                  </h4>
                  <small>
                    <i class="fas fa-calendar"></i> {{ event.date | moment('DD/MMM/YYYY') }} &nbsp;
                    <i class="fas fa-clock"></i> {{ event.start_time | moment('h:mm Z') }} &nbsp;
                    <i class="fas fa-stopwatch"></i> {{ event.end_time | moment('h:mm Z') }}&nbsp;
                  </small>
                  <br>
                  <small>
                    <b-link :to="`/eventos/${event.id}`" class="text-uppercase">
                      <i class="fas fa-info-circle"></i> Información
                    </b-link>
                    <span class="strong dorado-obscuro-text ">&nbsp;/ &nbsp; </span>
                    <span v-if="event.category" class="text-uppercase">
                      <i class="fas fa-tag"></i> {{event.category.name}}
                    </span>
                  </small>
                  <br>
                  <small>
                    <span v-if="event.links.facebook">
                      <span class="fa-stack fa-1x" v-b-tooltip.hover title="Se transmitira vía Facebook">
                        <i class="fas fa-square fa-stack-2x blue-text text-darken-1"></i>
                        <i class="fab fa-facebook-f fa-stack-1x "></i>
                      </span>
                    </span>
                    <span v-if="event.links.youtube">
                      <span class="fa-stack fa-1x" v-b-tooltip.hover title="Se transmitira vía Toutube">
                        <i class="fas fa-square fa-stack-2x red-text text-darken-2"></i>
                        <i class="fab fa-youtube fa-stack-1x "></i>
                      </span>
                    </span>
                    <span v-if="event.links.periscope">
                      <span class="fa-stack fa-1x" v-b-tooltip.hover title="Se transmitira vía Periscope">
                        <i class="fas fa-square fa-stack-2x cyan-text"></i>
                        <i class="fab fa-periscope fa-stack-1x "></i>
                      </span>
                    </span>
                    <span v-if="event.links.twitter">
                      <span class="fa-stack fa-1x" v-b-tooltip.hover title="Se transmitira vía Twitter">
                        <i class="fas fa-square fa-stack-2x light-blue-text text-lighten-1"></i>
                        <i class="fab fa-twitter fa-stack-1x "></i>
                      </span>
                    </span>
                  </small>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-col lg="12" class="mb-5 text-right">
            <b-button to="/eventos/" variant="danger" size="lg" class="dorado-obscuro   ">
              Todos los eventos <i class="fas fa-external-link-square-alt"></i>
            </b-button>
          </b-col>

        </b-row>
      </b-container>
    </div>
</template>

<script>

let date = new Date()
let day = `${(date.getDate())}`.padStart(2,'0');
let month = `${(date.getMonth()+1)}`.padStart(2,'0');
let year = date.getFullYear();
let hoy = `${year}-${month}-${day}`;

export default {
  name: 'UpcomingEventsFull',
  components: {
  },

  props: {
    title: String,
    dateEvent: String,
  },

  data() {
    return {
      upcomingEvents: {
        category: [],
        cover_image: [],
      },
      hoy: hoy,
      nowEvents: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted() {},



  /*
   * Ajuste segun fecha
   */
  async created() {

    await fetch(
      this.path.endpoint + '/upcoming-events/?_sort=date:asc&_limit=4&date_gte=' + hoy // <- Ajuste segun fecha
    ).then(response => response.json())
    .then(data => {
      this.nowEvents = data;
      //console.log(data);
      console.log(`${year}-${month}-${day}`)
    })
    .catch(err => {
      console.error(err);
    });

  },
  /* End Ajuste */



  methods: {},


}
</script>
