import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

/*
 * Category 1 => Igualdad de Género
 * Category 2 => Inclusión Financiera
 * Category 3 => Educación
 * Category 4 => Salud
 * Category 5 => Comunidades Indigenas
 * Category 6 => Deporte
 * Category 7 => Cultura
 * Category 8 => Vinculación
 * Category 9 => Estadisticas
 */

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
	},

	{
		path: "/charts",
		name: "Charts",
		component: () => import("../views/charts/Index.vue"),
	},

	{
		path: "/eventos/:page?",
		name: "UpcomingEvents",
		component: () => import("../views/UpcomingEvents/Index.vue"),
	},
	{
		path: "/eventos/:id",
		name: "UpcomingEventsShow",
		component: () => import("../views/UpcomingEvents/Show.vue"),
	},

	{
		path: "/posts",
		name: "Index",
		component: () => import("../views/posts/Index.vue"),
	},

	/* Secciones */

	{
		path: "/genero/",
		name: "CategoryGenero",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "genero",
			cfgNameCategory: "Igualdad de Género",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/genero/articulos/:page?",
		name: "ArticlesGenero",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "genero",
			cfgNameCategory: "Igualdad de Género",
		},
		component: () => import("../views/articles/Genero.vue"),
	},
	{
		path: "/genero/articulo/:canonical",
		name: "PageGenero",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "genero",
			cfgNameCategory: "Igualdad de Género",
		},
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/genero/programas/:page?",
		name: "ProgramsGenero",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "genero",
			cfgNameCategory: "Igualdad de Género",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/genero/programa/:canonical",
		name: "ProgramGenero",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "genero",
			cfgNameCategory: "Igualdad de Género",
		},
		component: () => import("../views/programs/Show.vue"),
	},

	{
		path: "/vaf/",
		name: "CategoryVaf",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaf",
			cfgNameCategory: "Inclusión Financiera",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/vaf/articulos/:page?",
		name: "ArticlesVaf",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaf",
			cfgNameCategory: "Inclusión Financiera",
		},
		component: () => import("../views/articles/Vaf.vue"),
	},
	{
		path: "/vaf/articulo/:canonical",
		name: "PageVaf",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaf",
			cfgNameCategory: "Inclusión Financiera",
		},
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/vaf/programas/:page?",
		name: "ProgramsVaf",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaf",
			cfgNameCategory: "Inclusión Financiera",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/vaf/programa/:canonical",
		name: "ProgramVaf",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaf",
			cfgNameCategory: "Inclusión Financiera",
		},
		component: () => import("../views/programs/Show.vue"),
	},

	{
		path: "/educacion/",
		name: "CategoryEducacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "educacion",
			cfgNameCategory: "Educación",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/educacion/articulos/:page?",
		name: "ArticlesEducacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "educacion",
			cfgNameCategory: "Educación",
		},
		component: () => import("../views/articles/Educacion.vue"),
	},
	{
		path: "/educacion/articulo/:canonical",
		name: "PageEducacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "educacion",
			cfgNameCategory: "Educación",
		},
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/educacion/programas/:page?",
		name: "ProgramsEducacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "educacion",
			cfgNameCategory: "Educación",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/educacion/programa/:canonical",
		name: "ProgramEducacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "educacion",
			cfgNameCategory: "Educación",
		},
		component: () => import("../views/programs/Show.vue"),
	},

	{
		path: "/salud/",
		name: "CategorySalud",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "salud",
			cfgNameCategory: "Salud",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/salud/articulos/:page?",
		name: "ArticlesSalud",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "salud",
			cfgNameCategory: "Salud",
		},
		component: () => import("../views/articles/Salud.vue"),
	},
	{
		path: "/salud/articulo/:canonical",
		name: "PageSalud",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "salud",
			cfgNameCategory: "Salud",
		},
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/salud/programas/:page?",
		name: "ProgramsSalud",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "salud",
			cfgNameCategory: "Salud",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/salud/programa/:canonical",
		name: "ProgramSalud",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "salud",
			cfgNameCategory: "Salud",
		},
		component: () => import("../views/programs/Show.vue"),
	},
	{
		path: "/salud/charts",
		name: "SaludCharts",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "salud",
			cfgNameCategory: "Salud",
		},
		component: () => import("../views/charts/Index.vue"),
	},

	{
		path: "/vaipoime/",
		name: "CategoryVaipoime",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaipoime",
			cfgNameCategory: "Pueblos Originarios",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/vaipoime/articulos/:page?",
		name: "ArticlesVaipoime",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaipoime",
			cfgNameCategory: "Pueblos Originarios",
		},
		component: () => import("../views/articles/Vaipoime.vue"),
	},
	{
		path: "/vaipoime/articulo/:canonical",
		name: "PageVaipoime",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaipoime",
			cfgNameCategory: "Pueblos Originarios",
		},
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/vaipoime/programas/:page?",
		name: "ProgramsVaipoime",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaipoime",
			cfgNameCategory: "Pueblos Originarios",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/vaipoime/programa/:canonical",
		name: "ProgramVaipoime",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vaipoime",
			cfgNameCategory: "Pueblos Originarios",
		},
		component: () => import("../views/programs/Show.vue"),
	},

	{
		path: "/deporte/",
		name: "CategoryDeporte",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "deporte",
			cfgNameCategory: "Deporte",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/deporte/articulos/:page?",
		name: "ArticlesDeporte",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "deporte",
			cfgNameCategory: "Deporte",
		},
		component: () => import("../views/articles/Deporte.vue"),
	},
	{
		path: "/deporte/articulo/:canonical",
		name: "PageDeporte",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "deporte",
			cfgNameCategory: "Deporte",
		},
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/deporte/programas/:page?",
		name: "ProgramsDeporte",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "deporte",
			cfgNameCategory: "Deporte",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/deporte/programa/:canonical",
		name: "ProgramDeporte",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "deporte",
			cfgNameCategory: "Deporte",
		},
		component: () => import("../views/programs/Show.vue"),
	},

	{
		path: "/cultura/",
		name: "CategoryCultura",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "cultura",
			cfgNameCategory: "Cultura Comunitaria",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/cultura/articulos/:page?",
		name: "ArticlesCultura",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "cultura",
			cfgNameCategory: "Cultura Comunitaria",
		},
		component: () => import("../views/articles/Cultura.vue"),
	},
	{
		path: "/cultura/articulo/:canonical",
		name: "PageCultura",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "cultura",
			cfgNameCategory: "Cultura Comunitaria",
		},
		//component: () => import("../views/posts/Show.vue")
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/cultura/programas/:page?",
		name: "ProgramsCultura",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "cultura",
			cfgNameCategory: "Cultura",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/cultura/programa/:canonical",
		name: "ProgramCultura",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "cultura",
			cfgNameCategory: "Cultura",
		},
		component: () => import("../views/programs/Show.vue"),
	},

	{
		path: "/vinculacion/",
		name: "CategoryVinculacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculación",
		},
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/vinculacion/articulos/:page?",
		name: "ArticlesVinculacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculación",
		},
		component: () => import("../views/articles/Vinculacion.vue"),
	},
	{
		path: "/vinculacion/articulo/:canonical",
		name: "PageVinculacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculación",
		},
		component: () => import("../views/pages/Show.vue"),
	},
	{
		path: "/vinculacion/programas/:page?",
		name: "ProgramsVinculacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculacion",
		},
		component: () => import("../views/programs/Index.vue"),
	},
	{
		path: "/vinculacion/programa/:canonical",
		name: "ProgramVinculacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculacion",
		},
		component: () => import("../views/programs/Show.vue"),
	},
	{
		path: "/vinculacion/eventos/:page?",
		name: "AgendaVinculacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculación",
		},
		component: () => import("@/views/events/All.vue"),
	},
	{
		path: "/vinculacion/evento/:uuid",
		name: "AgendaUuidVinculacion",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculación",
		},
		component: () => import("@/views/events/Show.vue"),
	},

	{
		path: "/vinculacion/rgmx/",
		name: "LandingRgmxView",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "vinculacion",
			cfgNameCategory: "Vinculación",
		},
		component: () => import("@/views/rgmx/Index.vue"),
	},
	{
		path: "/vinculacion/rgmx/lineamientos",
		//component: () => import("@/views/events/Show.vue"),
		beforeEnter(to, from, next) {
			window.location.href =
				"https://nextcloud.sre.gob.mx/index.php/s/8JmkndGCmC9B59Z";
		},
	},

	{
		path: "/estadisticas/",
		name: "CategoryEstadisticas",
		meta: {
			cfgShowMenu: false,
			cfgCategory: "estadisticas",
			cfgNameCategory: "Estadísticas",
		},
		component: () => import("../views/categories/Estadisticas.vue"),
	},

	/*
	 * Familias en Retorno
	 */

	{
		path: "/familiasenretorno/",
		name: "CategoryFamiliasEnRetorno",
		meta: {
			cfgShowMenu: false,
			cfgCategory: "familiasenretorno",
			cfgNameCategory: "Familias en Retorno",
		},
		//component: () => import('../views/categories/FamiliasEnRetorno.vue')
		component: () => import("../views/categories/Show.vue"),
	},
	{
		path: "/familiasenretorno/articulos/:page?",
		name: "ArticlesFamiliasEnRetorno",
		meta: {
			cfgShowMenu: false,
			cfgCategory: "familiasenretorno",
			cfgNameCategory: "Familias en Retorno",
		},
		component: () => import("../views/articles/FamiliasEnRetorno.vue"),
	},
	{
		path: "/familiasenretorno/articulo/:canonical",
		name: "PageFamiliasEnRetorno",
		meta: {
			cfgShowMenu: false,
			cfgCategory: "familiasenretorno",
			cfgNameCategory: "Familias en Retorno",
		},
		component: () => import("../views/pages/Show.vue"),
	},

	/*
	 * Blog general
	 */
	{
		path: "/blog/",
		name: "Blog",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "blog",
			cfgNameCategory: "Artículos",
		},
		//component: () => import('../views/Blog/Index.vue')
		redirect: "/",
	},
	{
		path: "/blog/articulo/:canonical",
		name: "BlogPage",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "blog",
			cfgNameCategory: "Artículos",
		},
		component: () => import("../views/pages/Show.vue"),
	},

	{
		path: "/casademexico/",
		name: "MagazineCasaMx",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "casademexico",
			cfgNameCategory: "Casa de México",
		},
		component: () => import("../views/CasaMx/Index.vue"),
	},
	{
		path: "/casademexico/articulo/:canonical",
		name: "MagazineCasaMxPage",
		meta: {
			cfgShowMenu: true,
			cfgCategory: "casademexico",
			cfgNameCategory: "Casa de México",
		},
		component: () => import("../views/CasaMx/Show.vue"),
	},

	/*
	 * Directorio
	 */
	{
		path: "/directorio/",
		name: "DirectorioIme",
		meta: {
			cfgShowMenu: false,
			cfgCategory: "directorio",
			cfgNameCategory: "Directorio IME",
		},
		component: () => import("../views/directory/Index.vue"),
	},

	/*
	 * Directorio PCEME
	 */
	{
		path: "/pceme/:sector?",
		name: "DirectorioPceme",
		meta: {
			cfgShowMenu: false,
			cfgCategory: "pceme",
			cfgNameCategory: "Directorio PCEME",
		},
		component: () => import("../views/directory/DirectoryPceme.vue"),
	},

	{
		path: "/:canonical",
		name: "SinglePages",
		component: () => import("../views/SinglePages.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	//base: process.env.VUE_APP_BASE_URL,
	routes,
});

export default router;
