import http from "@/services/http-common";

class DataCategories {

    getAll() {
        return http.get('/categories/?_sort=reorder:asc&show_menu=true');
    }

    getAllReorder() {
        return http.get('/categories/?_sort=reorder:asc&show_comunnities=true');
    }

    get(id) {
        return http.get(`/categories/${id}`);
    }

}

export default new DataCategories();
