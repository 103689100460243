<template>
  <div class="outstanding-primary-two-secondaries">
    <b-container class="mt-5 mb-5">
      <b-row class="">
        <b-col cols="12" class="">
          <h1><i class="fas fa-medal"></i> {{ title }}</h1>
          <hr class="line">
        </b-col>
      </b-row>
      <div class="mb-5">

        <b-row class="">
          <b-col class="post-primary" lg="8" md="8" sm="12" xs="12" >
            <b-col
              class="z-depth-3 blue-grey lighten-5 m-2 back"
              v-for="ppost in primaryPosts"
              :key="ppost.id"
              :style="`background-image: url(${ ppost.image_home_local ? path.storage_files + ppost.image_home_local.formats.large.url : ppost.image_home })`"
            >
              <div class="gradiente-homepage-post-primary white-text text-center p-4">
                <h3 class="medium text-shadow">
                  <b-link class="white-text" :to="`${ppost.category_id.slug}/articulo/${ppost.canonical}`"><!-- Ajustar para Slug -->
                    {{ ppost.title }}
                  </b-link>
                </h3>
                <p class="light text-shadow">{{ ppost.summary }}</p>
                <div class="block">
                  <b-badge class="rojo">
                    <i class="fas fa-tag"></i>&nbsp;
                    {{ ppost.category_id.name }}
                  </b-badge>&nbsp;
                  <b-badge class="dorado-obscuro">
                    <i class="fas fa-calendar-alt"></i>&nbsp;
                    {{ ppost.updated_at | moment('DD/MMM/YYYY') }} 
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-col>
          <b-col class="" lg="4" md="4" sm="12" xs="12" >
            <b-col
              cols="12"
              class="post-secondary mt-2 mb-3 z-depth-3 blue-grey lighten-5 clearfix"
              v-for="spost in secondaryPosts"
              :key="spost.id" 
              :style="`background-image: url(${ spost.image_home_local ? path.storage_files + spost.image_home_local.formats.medium.url : spost.image_home })`"
            >
              <div class="gradiente-homepage-post-secondary text-white text-center p-3">
                <h5 class="medium text-shadow">
                <b-link :to="`${spost.category_id.slug}/articulo/${spost.canonical}`"><!-- Ajustar para Slug -->
                  {{ spost.title }}
                </b-link>
                </h5>
                <div class="block">
                  <b-badge class="rojo">
                    <i class="fas fa-tag"></i>&nbsp;
                    {{ spost.category_id.name }}
                  </b-badge>&nbsp;
                  <b-badge class="verde">
                    <i class="fas fa-calendar-alt"></i>&nbsp;
                    {{ spost.updated_at | moment('DD/MMM/YYYY') }} 
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import DataBlogs from "@/services/data-blogs";

export default {
  name: 'PrimaryTwoSecondaries',
  components: {
    DataBlogs,
  },

  props: {
    title: String,
  },

  data() {
    return {
      secondaryPosts: [],
      primaryPosts: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted() {
    this.retrievePostsSecondary();
    this.retrievePostsPrimary();
  },

  methods: {

    retrievePostsSecondary() {
      DataBlogs.getAllBySecondary()
      .then(response => {
        this.secondaryPosts = response.data;
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    },

    retrievePostsPrimary() {
      DataBlogs.getAllByPrimary()
      .then(response => {
        this.primaryPosts = response.data;
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    },

  },

}
</script>
