import configApiService from './configApi'
import axios from 'axios';

export default axios.create({
    baseURL: configApiService.base_url,
    //crossdomain: true,
    //timeout: 1000,
    headers: {
        'Content-type': 'application/json'
        //'Access-Control-Allow-Origin': 'https://api-ime.k0s.ime.red',
    }
});
