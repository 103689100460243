import http from "@/services/http-common";

class DataBlogs {

    getAll() {
        return http.get('/blogs/');
    }

    /*
    * Category 1 => Igualdad de Género
    * Category 2 => Inclusión Financiera
    * Category 3 => Educación
    * Category 4 => Salud
    * Category 5 => Comunidades Indigenas
    * Category 6 => Deporte 
    */
    /*
    getAllByCategorySlug(slug) {
        return http.get(`/blogs?category_id.slug=${slug}`);
    }
    */

    getAllByCategoryGenero() {
        return http.get(`/blogs?category_id=1&type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:DESC`);
    }

    getAllByCategoryVaf() {
        return http.get(`/blogs?category_id=2&type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:DESC`);
    }

    getAllByCategoryEducacion() {
        return http.get(`/blogs?category_id=3&type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:DESC`);
    }

    getAllByCategorySalud() {
        return http.get(`/blogs?category_id=4&type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:DESC`);
    }

    getAllByCategoryVaipoime() {
        return http.get(`/blogs?category_id=5&type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:DESC`);
    }

    getAllByCategoryDeporte() {
        return http.get(`/blogs?category_id=6&type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:DESC`);
    }


    getAllByPublishedAt() {
        return http.get('/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:desc&_limit=5');
    }

    getLastPostsThree() {
        return http.get('/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:desc&_limit=3&post_type=3');
    }

    getLastPostsFour() {
        return http.get('/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:desc&_limit=4&post_type=5');
    }

    getAllByList() {
        return http.get('/blogs/?_sort=updated_at:desc&_limit=3&category_id_nin=13&post_type_in=3&post_type_in=4&post_type_in=5&post_type_in=6&type_publication_id_in=1&type_publication_id_in=5');
    }


    /*
     * Principales Post's de Home
     */
    getAllByPrimary() {
        return http.get('/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:desc&_limit=1&outstandingHome=Primary');
    }

    getAllBySecondary() {
        return http.get('/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:desc&_limit=2&outstandingHome=Secondary');
    }

    getAllByTertiary() {
        return http.get('/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=updated_at:desc&_limit=3&&outstandingHome=Tertiary');
    }
    /* Fin Post's de Home */


    findByCategorySlug(category_slug) {
        return http.get(`/blogs?category_id.slug=${category_slug}`);
    }

    get(id) {
        return http.get(`/blogs/${id}`);
    }

}

export default new DataBlogs();
