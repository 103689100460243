import http from "@/services/http-common";

class DataHomePage {
  getHomePage() {
    return http.get("/homepage/");
  }

  getBanners() {
    return http.get("/banners/?isGlobal=false&category.slug=homepage");
  }
}

export default new DataHomePage();
