<template>
	<section v-if="cfg" class="home">
		<HomePageSlide v-if="cfg.ConfigHome.Slide === true"></HomePageSlide>

		<PrimaryTwoSecondaries
			v-if="cfg.ConfigHome.Outstanding === 'OnePrimaryTwoSecondaries'"
			:title="`${cfg.ConfigHome.OutstandingTitle}`"
		></PrimaryTwoSecondaries>

		<LastPostsThree
			v-if="cfg.ConfigHome.LastPosts === 'ThreeColumns'"
			:title="`${cfg.ConfigHome.LastPostsTitle}`"
		></LastPostsThree>

		<LastPostsFour
			v-if="cfg.ConfigHome.LastPosts === 'FourColumns'"
			:title="`${cfg.ConfigHome.LastPostsTitle}`"
		></LastPostsFour>

		<UpcomingEventsFull
			v-if="cfg.ConfigHome.Eventos === true"
			:title="`${cfg.ConfigHome.EventsTitle}`"
		></UpcomingEventsFull>

		<AttentionImeThreeColumns
			v-if="cfg.ConfigHome.AttentionIme === 'ThreeColumns'"
			:title="`${cfg.ConfigHome.AttentionImeTitle}`"
		></AttentionImeThreeColumns>

		<AttentionImeFourColumns
			v-if="cfg.ConfigHome.AttentionIme === 'FourColumns'"
			:title="`${cfg.ConfigHome.AttentionImeTitle}`"
		></AttentionImeFourColumns>

		<AttentionImeTwoColumns
			v-if="cfg.ConfigHome.AttentionIme === 'TwoColumns'"
			:title="`${cfg.ConfigHome.AttentionImeTitle}`"
		></AttentionImeTwoColumns>
	</section>
</template>

<script>
import DataHomePage from "@/services/data-homepage";
import HomePageSlide from "@/components/Home/HomePageSlide.vue";
import PrimaryTwoSecondaries from "@/components/Custom/Outstanding/PrimaryTwoSecondaries.vue";
import LastPostsThree from "@/components/Custom/LatestPosts/LastPostsThree.vue";
import LastPostsFour from "@/components/Custom/LatestPosts/LastPostsFour.vue";
import UpcomingEventsFull from "@/components/Custom/UpcomingEvents/UpcomingEventsFull.vue";
import AttentionImeThreeColumns from "@/components/Custom/Communities/AttentionImeThreeColumns.vue";
import AttentionImeFourColumns from "@/components/Custom/Communities/AttentionImeFourColumns.vue";
import AttentionImeTwoColumns from "@/components/Custom/Communities/AttentionImeTwoColumns.vue";

export default {
	name: "HomePage",

	metaInfo: {
		title: "Instituto de los Mexicanos en el Exterior",
		titleTemplate: null,
	},

	components: {
		DataHomePage,
		HomePageSlide,
		PrimaryTwoSecondaries,
		LastPostsThree,
		LastPostsFour,
		UpcomingEventsFull,
		AttentionImeThreeColumns,
		AttentionImeFourColumns,
		AttentionImeTwoColumns,
	},

	data() {
		return {
			cfg: {
				ConfigHome: [],
			},
		};
	},

	mounted() {
		document.title = "Instituto de los Mexicanos en el Exterior";
		this.cfgHomePage();
	},

	methods: {
		cfgHomePage() {
			DataHomePage.getHomePage()
				.then((response) => {
					this.cfg = response.data;
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>
